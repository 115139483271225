.Dropdown_dropdown__2B0mb {
  position: relative;
  display: inline-flex;
}

.Dropdown_button__1fhs9 {
  background-color:#EEF3FF;
  border-radius: 4px;
  outline: none;
  -webkit-user-select: none;
          user-select: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  transition: filter 160ms ease-out;
  border: none;
  padding: 0 16px;
  font-weight: 600;
  font-size: 13px;
}

.Dropdown_button__1fhs9:hover {
  filter: brightness(0.9);
}

.Dropdown_active__eA2OE {
  filter: brightness(0.9);
}

.Dropdown_disabled__3zKmI {
  cursor: not-allowed;
}

.Dropdown_disabled__3zKmI:hover {
  filter: brightness(1) !important;
}

.Dropdown_menu__ilspN {
  position: absolute;
  top: 44px;
  right: 0;
  z-index: 1000;
  flex-direction: column;
  min-width: 180px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 12px 32px rgba(26, 26, 26, 0.2);
}

.Dropdown_menu__ilspN > ul {
  list-style: none;
  padding-left: 0;
  margin-top: 12px;
  margin-bottom: 12px;
}

.Dropdown_menuRight__pZZRH {
  left: 0;
}

.Item_item__2XH3X {
  position: relative;
  font-size: 14px;
  line-height: 1.4;
  padding: 8px 12px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  outline: none;
  transition: all 160ms ease-out;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.Item_item__2XH3X:hover {
  background-color: #EEF3FF;
  color: #2C5DE5;
}

.Item_item__2XH3X:hover > div {
  display: flex;
  flex-direction: column;
}

.Item_active__U-i3x {
  background-color: #EEF3FF;
  color: #2C5DE5 !important;
}
.Submenu_submenu__24PMh {
  background-color: #fff;
  min-width: 180px;
  border-radius: 4px;
  box-shadow: 0px 12px 32px rgba(26, 26, 26, 0.2);
  z-index: 1100;
  position: absolute;
  display: none;
  top: -5px;
  right: 98%;
}

.Submenu_submenu__24PMh > ul {
  list-style: none;
  padding-left: 0;
  margin-top: 12px;
  margin-bottom: 12px;
}

.Submenu_submenuRight__3lwSc {
  left: 98%;
}
.rh5v-DefaultPlayer_component {
    position: relative;
    font-family: Helvetica;
    font-size: 11px;
    background-color: #000;
}

.rh5v-DefaultPlayer_video {
    width: 100%;
    height: 100%;
}

.rh5v-DefaultPlayer_controls {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 34px;
    display: flex;
    background-color: rgba(0,0,0,0.7);
    opacity: 0;
    transition: opacity 0.2s;
}

.rh5v-DefaultPlayer_seek {
    flex-grow: 1;
}

.rh5v-DefaultPlayer_component:hover .rh5v-DefaultPlayer_controls {
    opacity: 1;
}
.rh5v-Time_component {
    padding: 0 10px 0 10px;
    line-height: 35px;
    color: #fff;
}

.rh5v-Time_current {
    margin-right: 5px;
}

.rh5v-Time_duration {
    margin-left: 5px;
    color: #919191;
}
.rh5v-Seek_component {
    position: relative;
}

.rh5v-Seek_track {
    position: absolute;
    top: 50%;
    left: 5px;
    right: 5px;
    height: 4px;
    transform: translateY(-50%);
    background-color: #3e3e3e;
}

.rh5v-Seek_buffer,
.rh5v-Seek_fill,
.rh5v-Seek_input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.rh5v-Seek_buffer {
    background-color: #5a5a5a;
}

.rh5v-Seek_fill {
    background: #fff;
}

.rh5v-Seek_input {
    width: 100%;
    opacity: 0;
    cursor: pointer;
}
.rh5v-Volume_component {
    position: relative;
}

.rh5v-Volume_component:hover {
    background-color: #000;
}

.rh5v-Volume_button {
    width: 34px;
    height: 34px;
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
}

.rh5v-Volume_button:focus {
    outline: 0;
}

.rh5v-Volume_icon {
    padding: 7px;
}

.rh5v-Volume_slider {
    display: none;
    position: absolute;
    right: 5px;
    bottom: 100%;
    left: 5px;
    height: 56px;
    background-color: #000;
}

.rh5v-Volume_component:hover .rh5v-Volume_slider {
    display: block;
}

.rh5v-Volume_track {
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 50%;
    width: 4px;
    transform: translateX(-50%);
    background-color: #3e3e3e;
}

.rh5v-Volume_fill,
.rh5v-Volume_input {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.rh5v-Volume_fill {
    background-color: #fff;
}

.rh5v-Volume_input {
    padding: 0;
    margin: 0;
    opacity: 0;
    -webkit-appearance: slider-vertical;
    cursor: pointer;
}.rh5v-Captions_component {
    position: relative;
}

.rh5v-Captions_component:hover {
    background-color: #000;
}

.rh5v-Captions_button {
    width: 34px;
    height: 34px;
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
}

.rh5v-Captions_button:focus {
    outline: 0;
}

.rh5v-Captions_icon {
    padding: 5px;
}

.rh5v-Captions_trackList {
    position: absolute;
    right: 0;
    bottom: 100%;
    display: none;
    background-color: rgba(0,0,0,0.7);
    list-style: none;
    padding: 0;
    margin: 0;
    color: #fff;
}

.rh5v-Captions_component:hover .rh5v-Captions_trackList {
    display: block;
}

.rh5v-Captions_trackItem {
    padding: 7px;
    cursor: pointer;
}

.rh5v-Captions_activeTrackItem,
.rh5v-Captions_trackItem:hover {
    background: #000;
}

.rh5v-Captions_activeTrackItem {
    text-decoration: underline;
}
.rh5v-PlayPause_component {}

.rh5v-PlayPause_component:hover {
    background-color: #000;
}

.rh5v-PlayPause_button {
    width: 34px;
    height: 34px;
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
}

.rh5v-PlayPause_button:focus {
    outline: 0;
}

.rh5v-PlayPause_icon {
    padding: 5px;
}
.rh5v-Fullscreen_component {}

.rh5v-Fullscreen_component:hover {
    background-color: #000;
}

.rh5v-Fullscreen_button {
    width: 34px;
    height: 34px;
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
}

.rh5v-Fullscreen_button:focus {
    outline: 0;
}

.rh5v-Fullscreen_icon {
    padding: 5px;
}
.rh5v-Overlay_component {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
}

.rh5v-Overlay_inner {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 50%;
    width: 60px;
    height: 60px;
    transform: translateY(-50%);
    margin-left: -30px;
    background-color: rgba(0,0,0,0.7);
    border-radius: 10px;
}

.rh5v-Overlay_icon {
    position: absolute;
    top: 50%;
    right: 0;
    left: 50%;
    margin-left: -20px;
    transform: translateY(-50%);
}

